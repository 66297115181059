.App {
  width: 100%;
  height: 100%;
  margin: 5rem auto;
  background-color: #fff;
}
.card-top {
  display: inline-block;
  border-radius: '50%';
  border-color: gray;
  padding: 7px;
  margin-top: 1rem;
  justify-content: center;
}
.App .slick-arrow.slick-prev,
.App .slick-arrow.slick-next {
  width: 5rem;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: '50%';
}
.App .slick-dots li button:before {
  font-size: 15px;
}
/* .slick-arrow.slick-next {
  left: 80rem;
}
.slick-arrow.slick-prev {
  left: 11rem;
} */
.slick-prev::before,
.slick-next::before {
  display: none;
}

.card {
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
  overflow: hidden;
}

.text {
  width: 70%;
  text-align: center;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .text {
    width: 50%;
  }
  .slick-arrow.slick-next {
    right: 10rem;
  }
  .slick-arrow.slick-prev {
    left: 10rem;
  }
}
/*
@media screen and (max-width: 300px) {
  .App .slick-arrow.slick-prev,
  .App .slick-arrow.slick-next {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .slick-arrow.slick-next {
    left: 17rem;
  }
  .slick-arrow.slick-prev {
    left: 0.5rem;
  }
} */
/* @media screen and (max-width: 400px) {
  .App .slick-arrow.slick-prev,
  .App .slick-arrow.slick-next {
    display: none;
  }
} */
/* @media screen and (max-width: 750px) {
  .App .slick-arrow.slick-prev,
  .App .slick-arrow.slick-next {
    display: none;
  }
} */