@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #6948ff;
  --secondary: #553bcb;
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #808080;
}

body {
  color: #333;
  font-family: 'Montserrat', sans-serif;
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
  background-color: #fff;
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
  -webkit-columns: #fff;
          columns: #fff;
}
.primary-color {
  color: #6948ff;
  color: var(--primary);
}

button {
  width: 100%;
  padding: 8px 0;
  border-radius: 4px;
  border: 1px solid #6948ff;
  border: 1px solid var(--primary);
  background-color: #6948ff;
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
}

.button:hover {
  border: 1px solid #553bcb;
  border: 1px solid var(--secondary);
  background-color: #553bcb;
  background-color: var(--secondary);
}

.hero {
  /* width: 100vh;
  height: 100vh; */
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to left,
      rgba(73, 50, 173, 0.5),
      rgba(73, 50, 173, 0.9)
    ),
    url('https://images.pexels.com/photos/1464223/pexels-photo-1464223.jpeg?auto=compress&cs=tinysrgb&w=1800');
  background-position: left;
  background-repeat: no-repeat;
}
.hero .container {
  max-width: 1240px;
  padding: 0 1rem;
  margin: auto;
}
/* animation*/

#scroll-container {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
}

#scroll-text {
  text-align: right;

  /* animation properties */
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/* second */
#scroll-contain {
  border-radius: 5px;
  overflow: hidden;
}

#scroll-tex {
  text-align: right;

  /* animation properties */
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 5s linear infinite;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.hero .content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.hero .col-1 {
  padding: 1rem 1rem 1rem 0;
  height: 70vh;
  display: flex;
  justify-content: center;
}
.hero .col-1 h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.hero .col-1 p {
  font-size: 1rem;
  color: var(--grey);
  line-height: 1.4;
  margin-top: 1.2rem;
}
.hero .used-by p {
  color: #000;
  font-size: 12px;
  margin: 1rem 0 1rem 0;
}
.hero .col-1 .icons i {
  color: snow;
  padding-right: 1rem;
}
.hero .col-1 {
  padding: 1rem;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8rem;
}
.hero .col-1 p {
  color: #fff;
}
.hero .form-layout {
  width: 400px;
  margin: auto;
  margin-top: 3rem;
  background-color: #fff;
  border-radius: 6px;
}
.hero .form-container {
  padding: 1.5rem 2rem;
}
.hero .sign-in-txt {
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 1rem;
}
.social-login i {
  background-color: #fff;
  padding: 16px 45px 6px 45px;
  border: 1px solid var(--lightgrey);
  border-radius: 4px;
}

.col-2 .divider p span {
  background-color: #fff;
  padding: 0 10px;
}

.col-2 .divider p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--lightgrey);
  line-height: 0.1rem;
  margin: 2rem 0 2rem;
}

form input {
  display: block;
  width: 100%;
  margin: 1.2rem 0;
  padding: 8px;
  border: 1px solid var(--lightgrey);
  border-radius: 4px;
}
.hero .form-footer {
  border-top: 1px solid var(--lightgrey);
  background-color: #f8f8f8;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.form-footer p {
  padding: 1.5rem 2rem;
}
.form-footer span {
  font-weight: 500;
}

@media screen and (max-width: 960px) {
  .hero {
    height: auto;
    width: 100%;
  }
  .hero .container {
    padding: 0 1rem;
  }
  .hero .content {
    grid-template-columns: 1fr;
  }
  .hero .col-1 {
    height: 90vh;
    padding: 0.2rem;
    max-width: 100%;
  }
  .hero h1 {
    font-size: 32px;
  }
  .hero .col-2 {
    display: block;
  }
  #scroll-tex {
    font-size: 1px;
    width: 20rem;
  }
  #scroll-text {
    font-size: 2px;
    width: '100%';
  }
}


header {
  width: 100%;
  height: 80px;
  background-color: var(--darkgrey);
  z-index: 10000;
}
header .navbar {
  max-width: 1240px;
  height: 100%;
  padding: 0 1rem;
  margin: auto;
  overflow: hidden;
  display: flex;

  justify-content: space-between;
}
header .logo {
  display: flex;
  align-items: center;
}

header .logo img {
  width: 100px;
  height: auto;
  margin-top: 25px;
}
.hamburger {
  display: none;
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 10000;
}
.nav-menu {
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 10000;
}
.nav-item {
  padding: 0 1.5rem;
}
.nav-link {
  color: #ffffff;
  font-weight: 700;
}
.nav-link:hover {
  transition: 0.3s;
  color: blue;
}

@media screen and (max-width: 960px) {
  .hamburger {
    display: block;
  }
  .nav-menu {
    position: fixed;
    left: -100%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--darkgrey);
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 0.4s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-item {
    margin: 1.5rem 0;
    font-size: 1.8rem;
  }
}

.pricing {
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  background-color: #fff;
}
.pricing .container {
  max-width: 1240px;
  padding: 1rem;
  margin: auto;
  overflow: hidden;
}
.pricing .top-content {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-bottom: 1px solid var(--lightgrey);
  padding-bottom: 3rem;
}
.pricing .top-content h2 {
  font-size: 38px;
  padding-bottom: 0.5rem;
}
.pricing .top-content span {
  color: var(--primary);
}
.pricing .top-content p {
  color: var(--grey);
}
.pricing .bottom-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 3rem auto;
}
.pricing .card {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 0;
}

.pricing .card div {
  display: flex;
  border-bottom: 1px solid var(--lightgrey);
  padding: 1rem 0;
}
.pricing .card .no-border {
  border: none;
}
.pricing .card span {
  color: var(--primary);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
.pricing .card h3 {
  font-size: 28px;
  margin: 0.7rem;
}

@media screen and (max-width: 960px) {
  .pricing .top-content {
    grid-template-columns: 1fr;
  }
  .pricing .container .bottom-content {
    /* display: none; */
    grid-template-columns: 1fr;
  }
  .pricing .top-content h2 {
    font-size: 20px;
  }
  /* .img {
    width: 10px;
    height: '15rem';
  } */
}

.App {
  width: 100%;
  height: 100%;
  margin: 5rem auto;
  background-color: #fff;
}
.card-top {
  display: inline-block;
  border-radius: '50%';
  border-color: gray;
  padding: 7px;
  margin-top: 1rem;
  justify-content: center;
}
.App .slick-arrow.slick-prev,
.App .slick-arrow.slick-next {
  width: 5rem;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: '50%';
}
.App .slick-dots li button:before {
  font-size: 15px;
}
/* .slick-arrow.slick-next {
  left: 80rem;
}
.slick-arrow.slick-prev {
  left: 11rem;
} */
.slick-prev::before,
.slick-next::before {
  display: none;
}

.card {
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
  overflow: hidden;
}

.text {
  width: 70%;
  text-align: center;
  margin: 0 auto;
}

@media screen and (min-width: 960px) {
  .text {
    width: 50%;
  }
  .slick-arrow.slick-next {
    right: 10rem;
  }
  .slick-arrow.slick-prev {
    left: 10rem;
  }
}
/*
@media screen and (max-width: 300px) {
  .App .slick-arrow.slick-prev,
  .App .slick-arrow.slick-next {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .slick-arrow.slick-next {
    left: 17rem;
  }
  .slick-arrow.slick-prev {
    left: 0.5rem;
  }
} */
/* @media screen and (max-width: 400px) {
  .App .slick-arrow.slick-prev,
  .App .slick-arrow.slick-next {
    display: none;
  }
} */
/* @media screen and (max-width: 750px) {
  .App .slick-arrow.slick-prev,
  .App .slick-arrow.slick-next {
    display: none;
  }
} */
.testimonial {
  width: 100%;
  color: #fff;
  margin: 3rem 0;
  background-color: #fff;
}
.testimonial .container {
  max-width: 1240px;
  padding: 1rem;
  margin: auto;
  overflow: hidden;
}
.testimonial .outline {
  background-image: linear-gradient(
      to left,
      rgba(73, 50, 173, 0.5),
      rgba(73, 50, 173, 0.9)
    ),
    url('https://images.pexels.com/photos/7680637/pexels-photo-7680637.jpeg?auto=compress&cs=tinysrgb&w=1250');
  width: 100%;
  height: 450px;
  background-position: left;
  border-radius: 10px;
  background-repeat: no-repeat;
}
.testimonial .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* max-width: 100%; */
  max-width: 50%;
  justify-content: center;
  padding: 3rem;
}

.testimonial .body {
  padding: 1.2rem 0;
}
.testimonial .name p {
  font-size: 14px;
  color: #fff;
}
/* .testimonial .content:hover {
  background-color: red;
  max-width: 100%;
} */
.testimonial p {
  color: #fff;
}

.scroll_container {
  height: 100px;
  overflow: hidden;
}

.scroll_text {
  height: 100%;
  text-align: center;

  /* animation properties */
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-animation: swipe 8s linear infinite;
  animation: swipe 8s linear infinite;
}

/* for Firefox */

/* for Chrome */
@-webkit-keyframes swipe {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes swipe {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 960px) {
  .testimonial {
    margin: 1rem 0;
  }
  .testimonial .content {
    max-width: 100%;
  }
  .testimonial .outline {
    background-image: linear-gradient(
        to left,
        rgba(73, 50, 173, 0.5),
        rgba(73, 50, 173, 0.9)
      ),
      url('https://images.pexels.com/photos/7680687/pexels-photo-7680687.jpeg?auto=compress&cs=tinysrgb&w=400');
    width: 100%;
    height: 450px;
    background-position: right;
    border-radius: 10px;
    background-repeat: no-repeat;
  }
}

.section .faq {
  width: 100%;
  margin: 3rem 0;
}
.section-faq .container {
  max-width: 1240px;
  padding: 1rem;
  margin: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.section-faq h3 {
  font-size: 28px;
  padding: 1rem 0;
}
.section-faq .col-1 p {
  color: var(--grey);
}
.section-faq span {
  color: var(--primary);
}
.section-faq h5 {
  font-size: 1rem;
  padding: 0.4rem 0;
}
.section-faq .faq {
  padding: 1.2rem 0;
}
.section-faq .faq p {
  color: var(--grey);
}

@media screen and (max-width: 960px) {
  .section-faq {
    margin: 1rem 0;
  }
  .section-faq .container {
    grid-template-columns: 1fr;
  }
}

.contact {
  width: 100%;
  margin: 3rem 0;
  display: flex;
}
.contact .container {
  max-width: 1240px;
  margin: auto;
  overflow: hidden;
  display: flex;
}
.contact .col-1 {
  flex: 1 0 45%;
  padding: 5rem 2rem;
}
.contact .col-1 h2 {
  font-size: 28px;
  padding: 0.4rem 0;
}

.contact .p {
  color: var(--grey);
}
.contact .col-1 .icons {
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
}

.contact .careers {
  padding: 2rem 0;
}
.contact span {
  color: var(--primary);
}
.contact .col-2 {
  flex: 1 0 55%;
  padding: 5rem 2rem;
}
/* .contact .address {
  margin-top: 1.5rem;
} */

.contact form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
}
.contact form input {
  margin-bottom: 1rem;
  padding: 0.6rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--lightgrey);
}

.contact form textarea {
  margin-bottom: 1rem;
  padding: 0.6rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid var(--lightgrey);
  font-family: 'Montserrat', sans-serif;
}

.contact form .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}
.contact .checkbox input {
  margin: 0;
  padding-right: 4px;
}
@media screen and (max-width: 960px) {
  .contact {
    margin: 1rem 0;
    display: block;
  }
  .contact .container {
    display: block;
  }
  .contact .col-1 {
    padding: 0 1rem;
  }
  .contact .col-2 {
    padding: 1rem;
  }
}

.footer {
  width: 100%;
  background-color: var(--darkgrey);
}
.footer .container {
  max-width: 1240px;
  padding: 3rem 1rem;
  margin: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.footer h6,
p {
  color: var(--grey);
  font-size: 14px;
  padding: 0.4rem 0;
}

.footer .link {
  color: var(--lightgrey);
  font-size: 14px;
  padding: 0.4rem 0;
  display: block;
}
.footer .subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer input {
  padding: 0.4rem;
  font-size: 14px;
  border-radius: 4px;
  width: 250px;
  border: 1px solid var(--lightgrey);
}
.footer button {
  margin-left: 0.8rem;
  width: 150px;
}
.footer .content {
  max-width: 1240px;
  margin: auto;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--grey);
}

@media screen and (max-width: 960px) {
  .footer .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
  }
  .footer .solutions,
  .support {
    display: none;
  }
  .footer .col-subscribe {
    margin: 2rem;
    text-align: center;
  }
  .footer .subscribe {
    flex-direction: column;
  }
  .footer input {
    width: 100%;
  }
  .footer button {
    width: 100%;
    margin: 0.2rem 0;
  }
  .footer-bottom .content {
    padding: 1rem;
    display: block;
    order: 1;
  }
  .footer-bottom .right {
    display: none;
  }
  .allicon {
    width: 20px;
    height: 20px;
  }
}

.all .tab {
  font-size: 20px;
  color: blue;
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .all .tab {
    font-size: 11px;
  }
}

.user-table-container {
  width: 100%;
  max-width: 1240px;
  margin: 20px auto;
  padding: 0;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
  table-layout: fixed;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  background-color: #f2f2f2;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.page-info {
  margin-bottom: 8px;
  font-weight: bold;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
}

.pagination-button {
  color: #000;
  padding: 8px 0px;
  width: 5rem;
  margin: 0 4px;
  text-decoration: none;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #fff;
}
#list_header {
  text-align: center;
  margin: 10px 0;
}
#text-page {
  text-align: center;
  width: 80%;
  margin: 10px auto;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media screen and (max-width: 600px) {
  #id-header {
    width: 50px; /* Adjust the width as needed */
  }

  th.email-header {
    width: 100px; /* Adjust the width as needed */
  }
  td {
    font-size: 12px;
  }
  #text-page {
    font-size: 14px;

    width: 100%;
  }
}

