header {
  width: 100%;
  height: 80px;
  background-color: var(--darkgrey);
  z-index: 10000;
}
header .navbar {
  max-width: 1240px;
  height: 100%;
  padding: 0 1rem;
  margin: auto;
  overflow: hidden;
  display: flex;

  justify-content: space-between;
}
header .logo {
  display: flex;
  align-items: center;
}

header .logo img {
  width: 100px;
  height: auto;
  margin-top: 25px;
}
.hamburger {
  display: none;
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 10000;
}
.nav-menu {
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 10000;
}
.nav-item {
  padding: 0 1.5rem;
}
.nav-link {
  color: #ffffff;
  font-weight: 700;
}
.nav-link:hover {
  transition: 0.3s;
  color: blue;
}

@media screen and (max-width: 960px) {
  .hamburger {
    display: block;
  }
  .nav-menu {
    position: fixed;
    left: -100%;
    flex-direction: column;
    justify-content: center;
    background-color: var(--darkgrey);
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 0.4s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-item {
    margin: 1.5rem 0;
    font-size: 1.8rem;
  }
}
