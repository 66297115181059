.user-table-container {
  width: 100%;
  max-width: 1240px;
  margin: 20px auto;
  padding: 0;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
  table-layout: fixed;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  background-color: #f2f2f2;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.page-info {
  margin-bottom: 8px;
  font-weight: bold;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
}

.pagination-button {
  color: #000;
  padding: 8px 0px;
  width: 5rem;
  margin: 0 4px;
  text-decoration: none;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #fff;
}
#list_header {
  text-align: center;
  margin: 10px 0;
}
#text-page {
  text-align: center;
  width: 80%;
  margin: 10px auto;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@media screen and (max-width: 600px) {
  #id-header {
    width: 50px; /* Adjust the width as needed */
  }

  th.email-header {
    width: 100px; /* Adjust the width as needed */
  }
  td {
    font-size: 12px;
  }
  #text-page {
    font-size: 14px;

    width: 100%;
  }
}
